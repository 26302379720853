type Route = {
  PATH: string;
  NAME: string;
};

export interface RoutesType {
  [key: string]: Route;
}

export const ROUTES: RoutesType = {
  LOGIN: {
    PATH: "/login",
    NAME: "login",
  },

  ROOT: {
    PATH: "/",
    NAME: "root",
  },

  CAMPAIGN_SELECTION: {
    PATH: "/campaign-selection",
    NAME: "campaignSelection",
  },

  MANAGE_OWN_MEDIA: {
    PATH: "/manage-own-media",
    NAME: "manageOwnMedia",
  },

  NOT_FOUND: {
    PATH: "/not-found",
    NAME: "notFound",
  },

  EXPLORE_OCCASION: {
    PATH: "/explore-occasion",
    NAME: "exploreOccasion",
  },

  LINKING_OCCASIONS: {
    PATH: "/linking-occasions",
    NAME: "linkingOccasions",
  },

  LINKING_SECTIONS: {
    PATH: "/linking-sections",
    NAME: "linkingSections",
  },

  MY_SAVED_OCCASIONS: {
    PATH: "/my-saved-occasions",
    NAME: "myOccasions",
  },
  MY_MEDIA_PROPERTIES: {
    PATH: "/my-media-properties",
    NAME: "myMediaProperties",
  },
  DEVELOPMENT: {
    PATH: "/development",
    NAME: "development",
  },
};
