export enum JobOperationStatus {
  Ok = "ok",
  Error = "error",
}

export enum JobStatus {
  Running = "running",
  Finished = "finished",
  Error = "error",
}

export enum Status {
  Linked = "linked",
  PushStarted = "push_started",
  Pushed = "pushed",
  PushedError = "push_error",
  RecallStarted = "recall_started",
  Recalled = "recalled",
  RecallError = "recall_error",
  Error = "error",
}

export enum YoutubeStatus {
  Error = "error",
  LinkSuccess = "linked",
  PushStarted = "push_started",
  PushSuccess = "pushed",
  PushError = "push_error",
  PushValidationStarted = "validate_pushed_started",
  PushValidationSuccess = "validate_pushed_ok",
  PushValidationError = "validate_pushed_error",
  RecallStarted = "recall_started",
  RecallSuccess = "recalled",
  RecallError = "recall_error",
  RecallValidationStarted = "validate_recalled_started",
  RecallValidationSuccess = "validate_recalled_ok",
  RecallValidationError = "validate_recalled_error",
}
