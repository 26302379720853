import { useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { OccasionInstanceItem, Statistics } from "@/helpers/mapping/mappingOccasion";
import lang from "@/i18n";
import { useAppStore } from "@/store/app/appStore";

export interface JobResponseBody {
  status?: string;
  responseStatus?: string;
  occasionInstanceId?: string;
  jobId?: string;
  occasionInstanceItems?: OccasionInstanceItem[];
  statistics?: Statistics;
}
export const useJobStatusRead = async (
  jobId: string,
  errorHandlingDisabled?: boolean
): Promise<JobResponseBody | null> => {
  const appStore = useAppStore();
  const { showToast } = appStore;

  const { request, data } = useApi<JobResponseBody>({
    path: (API_ROUTES.JOB_STATUS as (value: string) => string)(jobId),
  });

  try {
    await request();

    if (data.value) {
      return data.value;
    }
  } catch (e) {
    if (errorHandlingDisabled) {
      throw e;
    }
    showToast({
      summary: lang.message.occasion,
      severity: "error",
      detail: lang.error.somethingWentWrong,
    });
  }

  return null;
};
