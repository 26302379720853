import { useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import lang from "@/i18n";
import { useAppStore } from "@/store/app/appStore";
import { ApiPullCampaign } from "@/types/api/activationCampaignTypes";

type PullCampaignParams = {
  strategy_id: string;
};

export const usePullCampaignsRead = async (params: PullCampaignParams): Promise<ApiPullCampaign[]> => {
  const appState = useAppStore();
  const { updateIsLoading, showToast } = appState;
  const { request, data } = useApi<ApiPullCampaign[]>({
    path: API_ROUTES.PULL_CAMPAIGNS as string,
    queryParams: params,
  });

  updateIsLoading(true);

  try {
    await request();

    if (data.value) {
      return data.value;
    }
  } catch (e) {
    showToast({
      severity: "error",
      detail: lang.error.somethingWentWrong,
    });
  } finally {
    updateIsLoading(false);
  }

  return [];
};
