import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import { CallbackTypes, decodeCredential, googleLogout, googleSdkLoaded } from "vue3-google-login";

import { ROUTES } from "@/constants/routes";
import { MEDIA_TOKEN_KEY } from "@/hooks/apiHooks/media/useMediaTokenRead";
import router from "@/router";
import { useCatalogStore } from "@/store/catalog/catalogStore";

export interface User {
  email: string;
  familyName: string;
  givenName: string;
  name: string;
  picture: string;
}

export const API_TOKEN_KEY = "wpp_token";

export const useUserStore = defineStore("userStore", () => {
  const token = localStorage.getItem(API_TOKEN_KEY);

  const isUserHaveAccess = ref(true);
  const userData = token ? (decodeCredential(token) as Record<string, string>) : null;

  const user = ref<User | null>(
    userData
      ? {
          email: userData?.email,
          familyName: userData?.family_name,
          givenName: userData?.given_name,
          name: userData?.name,
          picture: userData?.picture,
        }
      : null
  );
  const apiToken = ref<string | null>(token ? token : null);

  const isShowMetric = ref(false);

  const updateUser = (userData: User | null): void => {
    user.value = userData;
  };

  const updateApiToken = (token: string | null): void => {
    token ? localStorage.setItem(API_TOKEN_KEY, token) : localStorage.removeItem(API_TOKEN_KEY);
    apiToken.value = token;
  };

  const updateUserData: CallbackTypes.CredentialCallback = async (response) => {
    const userData = decodeCredential(response.credential) as Record<string, string>;

    updateApiToken(response.credential);

    updateUser({
      email: userData?.email,
      familyName: userData?.family_name,
      givenName: userData?.given_name,
      name: userData?.name,
      picture: userData?.picture,
    });
  };

  const updateUserAccess = (isHaveAccess: boolean) => {
    isUserHaveAccess.value = isHaveAccess;
  };

  const logout = async () => {
    const catalogStore = useCatalogStore();
    const { isFullCatalogLoaded } = storeToRefs(catalogStore);
    updateShowMetric(false);
    googleLogout();
    googleSdkLoaded(async (google) => {
      if (user.value) {
        google.accounts.id.revoke(user.value?.email, async () => {
          await router.replace(ROUTES.LOGIN.PATH);
          localStorage.removeItem(API_TOKEN_KEY);
          localStorage.removeItem(MEDIA_TOKEN_KEY);
          updateUser(null);
          updateApiToken(null);
          isUserHaveAccess.value = false;
          isFullCatalogLoaded.value = false;
          catalogStore.setCatalog({});
        });
      } else {
        await router.replace(ROUTES.LOGIN.PATH);
        localStorage.removeItem(API_TOKEN_KEY);
        localStorage.removeItem(MEDIA_TOKEN_KEY);
        updateApiToken(null);
        isUserHaveAccess.value = false;
        isFullCatalogLoaded.value = false;
        catalogStore.setCatalog({});
      }
    });
  };

  const updateShowMetric = (value: boolean) => {
    isShowMetric.value = value;
  };

  return {
    user,
    apiToken,
    isUserHaveAccess,
    isShowMetric,
    updateUser,
    updateApiToken,
    updateUserData,
    logout,
    updateUserAccess,
    updateShowMetric,
  };
});
