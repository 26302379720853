import { RequestMethod, useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import lang from "@/i18n";
import { useAppStore } from "@/store/app/appStore";
import { usePaidMediaStore } from "@/store/paidMediaStore/paidMediaStore";
import { Status, YoutubeStatus } from "@/types/flowTypes";
import { LinkedOccasionBody, LinkedOccasionMessage, ResponseLinkedOccasion } from "@/types/linkedOccasion";

export const useOccasionsLinkedOccasionDelete = (
  status: Status | YoutubeStatus | null,
  defaultMessage: LinkedOccasionMessage
) => {
  return async (body: LinkedOccasionBody, message?: LinkedOccasionMessage): Promise<ResponseLinkedOccasion | null> => {
    const appStore = useAppStore();
    const { updateIsLoading, showToast } = appStore;

    const paidMediaStore = usePaidMediaStore();
    const { fetchLinkedItems } = paidMediaStore;

    const preparedBody = {
      ...body,
    };

    if (status) {
      preparedBody.status = status;
    }

    const { request, data } = useApi<ResponseLinkedOccasion>({
      body: preparedBody,
      method: RequestMethod.Delete,
      path: `${API_ROUTES.LINKED_OCCASION}/${body.occasion_instance_id}`,
    });

    updateIsLoading(true);

    try {
      await request();
      await fetchLinkedItems();
      showToast({
        detail: message?.successMessage ?? defaultMessage.successMessage,
        severity: defaultMessage?.successMessageSeverity ?? "success",
      });

      if (data.value) {
        return data.value;
      }
    } catch (e) {
      showToast({
        severity: "error",
        detail: message?.errorMessage || defaultMessage.errorMessage || lang.error.somethingWentWrong,
      });
    } finally {
      updateIsLoading(false);
    }

    return null;
  };
};
