import { useOccasionsLinkedOccasionUpdateForFlow } from "@/hooks/apiHooks/occasions/linked/useOccasionsLinkedOccasionUpdateForFlow";
import lang from "@/i18n";
import { Status, YoutubeStatus } from "@/types/flowTypes";

export const youtubeFlowTrigger = {
  onPushValidateStart: useOccasionsLinkedOccasionUpdateForFlow(
    YoutubeStatus.PushValidationStarted,
    {
      successMessage: lang.flow.toast.youtube[YoutubeStatus.PushValidationStarted],
    },
    true
  ),

  onRecallValidateStart: useOccasionsLinkedOccasionUpdateForFlow(
    YoutubeStatus.RecallValidationStarted,
    {
      successMessage: lang.flow.toast.youtube[YoutubeStatus.RecallValidationStarted],
    },
    true
  ),

  onPushValidateSuccess: useOccasionsLinkedOccasionUpdateForFlow(YoutubeStatus.PushValidationSuccess, {
    successMessage: lang.flow.toast.youtube[YoutubeStatus.PushValidationSuccess],
  }),

  onRecallValidateSuccess: useOccasionsLinkedOccasionUpdateForFlow(YoutubeStatus.RecallValidationSuccess, {
    successMessage: lang.flow.toast.youtube[YoutubeStatus.RecallValidationSuccess],
  }),

  onPushValidateError: useOccasionsLinkedOccasionUpdateForFlow(YoutubeStatus.PushValidationError, {
    successMessage: lang.flow.toast.youtube[YoutubeStatus.PushValidationError],
    successMessageSeverity: "error",
  }),

  onRecallValidateError: useOccasionsLinkedOccasionUpdateForFlow(YoutubeStatus.RecallValidationError, {
    successMessage: lang.flow.toast.youtube[YoutubeStatus.RecallValidationError],
    successMessageSeverity: "error",
  }),

  onPushStart: useOccasionsLinkedOccasionUpdateForFlow(YoutubeStatus.PushStarted, {
    successMessage: lang.flow.toast.youtube[YoutubeStatus.PushStarted],
    errorMessage: lang.error.pushSdfGeneratingStarted,
  }),

  onPushSuccess: useOccasionsLinkedOccasionUpdateForFlow(YoutubeStatus.PushSuccess, {
    successMessage: lang.flow.toast.youtube[YoutubeStatus.PushSuccess],
    errorMessage: lang.error.pushSdfGenerating,
  }),

  onPushError: useOccasionsLinkedOccasionUpdateForFlow(Status.PushedError, {
    successMessage: lang.flow.toast.youtube[YoutubeStatus.PushError],
    errorMessage: lang.flow.toast.youtube[YoutubeStatus.PushError],
    successMessageSeverity: "error",
  }),

  onRecallStart: useOccasionsLinkedOccasionUpdateForFlow(YoutubeStatus.RecallStarted, {
    successMessage: lang.flow.toast.youtube[YoutubeStatus.RecallStarted],
    errorMessage: lang.error.recallSdfStarted,
  }),

  onRecallSuccess: useOccasionsLinkedOccasionUpdateForFlow(YoutubeStatus.RecallSuccess, {
    successMessage: lang.flow.toast.youtube[YoutubeStatus.RecallSuccess],
    errorMessage: lang.error.recallSdfFinished,
  }),
};
