import { cleanTitle } from "@/helpers/mapping/mappingOccasion";
import { Status } from "@/types/flowTypes";
import { ApiPullLinkedOccasion, LinkedSection, PullSection } from "@/types/ownMediaTypes";

export const prepareLinkedSectionsData = (
  sections: PullSection[],
  occasions: ApiPullLinkedOccasion[]
): LinkedSection[] => {
  const preparedData: LinkedSection[] = [];

  sections.forEach((section: PullSection) => {
    const relatedLinkedOccasions = occasions.find((occasion) => occasion.section_id === section.id) ?? null;
    if (relatedLinkedOccasions?.occasion_instances) {
      relatedLinkedOccasions.occasion_instances.forEach((occasion, occasionIndex) => {
        preparedData.push({
          sectionId: section?.id,
          sectionName: section?.name,
          occasionName: occasion?.details.request.name,
          occasionTitle: cleanTitle(occasion?.details.request.name),
          occasionId: occasion?.id,
          occasionInstanceId: occasion?.details?.occasion_instance_id,
          occasionDefault: relatedLinkedOccasions.default === occasion?.details?.occasion_instance_id,
          occasionInstanceReach: occasion?.details?.statistics.reach,
          campaignId: relatedLinkedOccasions?.campaign_id,
          status: Status.Linked,
          occasionPriority: occasionIndex + 1,
          geographies: occasion?.details?.request?.geographies?.geographies,
          geographyFilter: occasion?.details?.request?.geography_filter,
          timeFilter: occasion?.details?.request?.time_filter,
          occasionInstance: occasion,
          weight: occasion?.details?.request?.weight,
        });
      });
    }
  });

  return preparedData;
};
