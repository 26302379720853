import { useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import lang from "@/i18n";
import { useAppStore } from "@/store/app/appStore";
import { LinkedOccasionParams, ResponseLinkedOccasion } from "@/types/linkedOccasion";

export const useOccasionsLinkedOccasionsRead = async (
  params: LinkedOccasionParams
): Promise<ResponseLinkedOccasion[]> => {
  const { showToast } = useAppStore();
  const { request, data } = useApi<ResponseLinkedOccasion[]>({
    path: API_ROUTES.LINKED_OCCASION as string,
    queryParams: params,
  });

  try {
    await request();

    if (data.value) {
      return data.value;
    }
  } catch (e) {
    showToast({
      summary: lang.error.getLinkedOccasions,
      severity: "error",
    });
  }

  return [];
};
