import { ComputedFilter, Filter } from "@/types/filters";
import { TreeSelectOption } from "@/types/treeSelect";

export enum FilterKey {
  Categories = "categories",
  Consumptions = "consumptions",
  DayParts = "dayparts",
  DaysOfWeek = "days_of_week",
  Keywords = "keywords",
  MacroActivities = "macro_activities",
  MacroOccasions = "macro_occasions",
  Places = "places",
  Pois = "pois",
}

export enum LoaderType {
  Loader = "loader",
  VideoLoader = "videoLoader",
}

export enum CategoryValue {
  Use = "use",
}

export const filterList: Filter[] = [
  {
    key: FilterKey.Categories,
    name: "Occasion Type",
    isMultiple: true,
  },
  {
    key: FilterKey.Places,
    name: "Macro-Location",
    isMultiple: true,
    isShown: (data) => {
      return !data[FilterKey.Categories] || (data[FilterKey.Categories] ?? []).includes(CategoryValue.Use);
    },
  },
  {
    key: FilterKey.MacroActivities,
    name: "Macro-Occasion",
    isTree: true,
    apiDataTransformer: (filters) =>
      filters && filters[FilterKey.MacroActivities] ? filters[FilterKey.MacroActivities].map((item) => item?.id) : [],
    gridArea: "2 / 1 / 3 / 2",
  },
];

export const computedFilterList: ComputedFilter[] = [
  {
    key: FilterKey.MacroOccasions,
    isComputed: true,
    apiDataTransformer: (filters) => {
      if (!filters) {
        return [];
      }

      const macroActivities = filters[FilterKey.MacroActivities] || [];

      const prepared = macroActivities.reduce((acc: string[], current: TreeSelectOption) => {
        const currentChildren = current.children?.map((child) => child.id) ?? [];
        return [...acc, ...currentChildren];
      }, []);

      return Array.from(new Set(prepared));
    },
  },
  {
    key: FilterKey.Keywords,
    isComputed: true,
  },
];

export const allFilters: (Filter | ComputedFilter)[] = [...filterList, ...computedFilterList];

export const MIN_OCCASIONS_IN_SET = 3;
export const MAX_OCCASIONS_IN_SET = 12;
