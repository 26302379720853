import { Lang } from "@/types/lang";

export default <Lang>{
  alwaysOnCampaign: "Always-On Campaign",
  endDate: "End Date",
  campaignPriority: "Campaign Priority",
  chooseCampaign: "Choose Campaign",
  endpoint: "Endpoint",
  description: "Description",
  domain: "Domain",
  login: "Login",
  mediaPropertyName: "Media Property Name",
  mediaType: "Media Type",
  password: "Password",
  startDate: "Start Date",
  occasionStrategy: "Occasion Strategy",
  myOccasionsSets: "My Occasions Sets",
};
