import { storeToRefs } from "pinia";

import { usePaidMediaStore } from "@/store/paidMediaStore/paidMediaStore";
import { ApiDsp } from "@/types/api";

export const useGetDsp = (lineItemIds: string[]): ApiDsp => {
  const paidMediaStore = usePaidMediaStore();
  const { campaignParams } = storeToRefs(paidMediaStore);

  const result: ApiDsp = {
    dsp_type: campaignParams.value.activationPlatform?.value?.dsp_type ?? "",
    dsp_id: {
      advertiserId: campaignParams?.value?.activationPlatform?.value?.advertiserId?.toString() ?? "",
      campaignId: campaignParams?.value?.campaign?.id?.toString() ?? "",
      lineItemIds: lineItemIds ?? [],
    },
  };

  const insertionOrderIds = campaignParams.value.insertionOrder?.id ? [campaignParams.value.insertionOrder.id] : [];
  if (insertionOrderIds.length) {
    result.dsp_id["insertionOrderIds"] = insertionOrderIds;
  }

  return result;
};
