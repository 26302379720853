import { useOccasionsLinkedOccasionDelete } from "@/hooks/apiHooks/occasions/linked/useOccasionsLinkedOccasionDelete";
import { useOccasionsLinkedOccasionLink } from "@/hooks/apiHooks/occasions/linked/useOccasionsLinkedOccasionLink";
import { useOccasionsLinkedOccasionUpdateForFlow } from "@/hooks/apiHooks/occasions/linked/useOccasionsLinkedOccasionUpdateForFlow";
import lang from "@/i18n";
import { Status } from "@/types/flowTypes";

export const standardFlowTrigger = {
  onLinkSuccess: useOccasionsLinkedOccasionLink(Status.Linked, {
    successMessage: lang.flow.toast.link_success,
    errorMessage: lang.flow.toast.link_error,
  }),

  onUnlinkSuccess: useOccasionsLinkedOccasionDelete(null, {
    successMessage: lang.flow.toast.unlink_success,
    errorMessage: lang.flow.toast.unlink_error,
  }),

  onPushStart: useOccasionsLinkedOccasionUpdateForFlow(Status.PushStarted, {
    successMessage: lang.success.pushStartedOccasions,
    errorMessage: lang.error.pushStartedOccasions,
  }),

  onPushSuccess: useOccasionsLinkedOccasionUpdateForFlow(Status.Pushed, {
    successMessage: lang.success.pushedOccasions,
    errorMessage: lang.error.pushedOccasions,
  }),

  onPushError: useOccasionsLinkedOccasionUpdateForFlow(Status.PushedError, {
    successMessage: lang.error.pushedOccasions,
    errorMessage: lang.error.pushedOccasions,
    successMessageSeverity: "error",
  }),

  onRecallStart: useOccasionsLinkedOccasionUpdateForFlow(Status.RecallStarted, {
    successMessage: lang.success.recallStarted,
    errorMessage: lang.error.recallNotStarted,
  }),

  onRecallSuccess: useOccasionsLinkedOccasionUpdateForFlow(Status.Recalled, {
    successMessage: lang.success.recallFinished,
    errorMessage: lang.error.recallNotFinished,
  }),

  onRecallError: useOccasionsLinkedOccasionUpdateForFlow(Status.RecallError, {
    successMessage: lang.error.recallNotFinished,
    errorMessage: lang.error.recallNotFinished,
    successMessageSeverity: "error",
  }),
};
