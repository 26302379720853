import { Lang } from "@/types/lang";

export default <Lang>{
  add: "Add",
  addToSet: "Add to Set",
  apply: "Apply",
  authorize: "Authorize",
  back: "Back",
  cancel: "Cancel",
  clear: "Clear",
  clearAll: "Clear All",
  clickAgain: "Click to try again",
  close: "Close",
  confirm: "Confirm",
  confirmSdfRecall: "Confirm Recall",
  continue: "Continue",
  createNewMediaProperty: "Create New Media Property",
  createNew: "Create New",
  delete: "Delete",
  discard: "Discard",
  downloadSdf: "Download SDF",
  downloadCSV: "Download CSV",
  downloadRecallSdf: "Download Recall SDF",
  edit: "Edit",
  forceUnlink: "Force Unlink",
  generateRecallSdf: "Generate Recall SDF",
  generateSdf: "Generate SDF",
  link: "Link",
  ok: "OK",
  preview: "Preview",
  push: "Push",
  pushedValidate: "Confirm In-Platform",
  recall: "Recall",
  remove: "Remove",
  refine: "Refine",
  reset: "Reset",
  review: "Review",
  save: "Save",
  setTo: (value: string): string => `Set to ${value}`,
  singIn: "Sign In",
  sortBy: (value: string): string => `Sort By ${value}`,
  unlink: "Unlink",
  validate: "Validate",
  saveStrategy: "Save Strategy",
  saveOccasionsSet: "Save Occasions Set",
  saveOccasions: "Save Occasion",
};
