import { cloneDeep, isEqual } from "lodash";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";

import { defaultFilterValues, defaultStrategyValue } from "@/constants/occasions";
import { collapseSpacesAndTrim } from "@/helpers/formatters/trimFormatters";
import { ApiOccasion, getOccasionsFromSet } from "@/helpers/mapping/mappingOccasion";
import { OccasionBeatResponse } from "@/hooks/apiHooks/occasion/useOccasionBeatRead";
import {
  OccasionInstancePostBody,
  ResponsePostOccasion,
} from "@/hooks/apiHooks/occasion/useOccasionOccasionInstantiate";
import { useOccasionsSetsRead } from "@/hooks/apiHooks/occasionsSets/useOccasionsSetsRead";
import lang from "@/i18n";
import { createNewOccasionSetItem } from "@/settings/occasionSet";
import { useAppStore } from "@/store/app/appStore";
import { CleanFilters, Filters } from "@/types/filters";
import { InitialSetValues, Occasion, OccasionInSet, OccasionMapData, Strategy } from "@/types/occasion";
import { OccasionToSaveSet, OccasionsSetPostResponse } from "@/types/occasionSetTypes";

export const INITIAL_SET = {
  setName: "",
  occasions: [],
};

interface CleanApiResponse {
  occasions: null | Record<string, ApiOccasion>;
  occasionDetails: null | ResponsePostOccasion;
}

export const useOccasionStore = defineStore("occasionStore", () => {
  const appStore = useAppStore();
  const { showToast } = appStore;

  const occasions = ref<Occasion[]>([]);
  const occasionsMapData = ref<OccasionMapData[]>([]);
  const occasionBeatData = ref<OccasionBeatResponse[]>([]);
  const isOccasionSelectionMounted = ref(false);
  const inputOccasionSet = ref<OccasionsSetPostResponse>(createNewOccasionSetItem);
  const availableOccasionSets = ref<OccasionsSetPostResponse[]>([]);
  const inputOccasionSetName = ref("");

  const openedPanels = reactive<Record<string, boolean>>({
    1: true,
    2: false,
    3: true,
    4: true,
  });

  const activePanels = reactive<Record<string, boolean>>({
    1: true,
    2: false,
    3: false,
    4: false,
  });

  const visibleCollapsePanelToggles = reactive<Record<string, boolean>>({
    1: true,
    2: true,
    3: true,
    4: true,
  });

  const isLeftPanelCollapsed = ref(false);

  const strategy = reactive<Strategy & Filters & CleanFilters>({
    strategyValue: cloneDeep(defaultStrategyValue),
    filterValues: cloneDeep(defaultFilterValues),
    cleanFilterValues: cloneDeep(defaultFilterValues),
  });

  const currentStrategy = reactive<Strategy & Filters>({
    strategyValue: cloneDeep(defaultStrategyValue),
    filterValues: cloneDeep(defaultFilterValues),
  });

  const geoSearchValues = ref<string[]>([]);
  const relevantOccasions = ref<Occasion[]>([]);
  const selectedOccasion = ref<Occasion | null>(null);
  const occasionsInSet = ref<OccasionInSet[]>([]);
  const occasionsSelectedInSet = ref<Occasion[]>([]);
  const occasionsToAddInSet = ref<Occasion[]>([]);
  const cleanApiResponse = reactive<CleanApiResponse>({
    occasions: null,
    occasionDetails: null,
  });

  const initialSetValues = ref<InitialSetValues>({ ...INITIAL_SET });

  const updateOccasions = (value: Occasion[]) => {
    occasions.value = value;
  };

  const updateOccasionsMapData = (value: OccasionMapData[]) => {
    occasionsMapData.value = value;
  };

  const updateOccasionBeatData = (value: OccasionBeatResponse[]) => {
    occasionBeatData.value = value;
  };

  const updateIsOccasionSelectionMounted = (value: boolean) => {
    isOccasionSelectionMounted.value = value;
  };

  const updateInputOccasionSet = (value: OccasionsSetPostResponse) => {
    inputOccasionSet.value = value;
  };

  const updateAvailableOccasionSets = (value: OccasionsSetPostResponse[]) => {
    availableOccasionSets.value = value;
  };

  const updateInputOccasionSetName = (value: string) => {
    inputOccasionSetName.value = value;
  };

  const updateGeoSearchValues = (value: string[]) => {
    geoSearchValues.value = value;
  };

  const updateRelevantOccasions = (value: Occasion[]) => {
    relevantOccasions.value = value;
  };

  const updateSelectedOccasion = (value: Occasion | null) => {
    selectedOccasion.value = value;
  };

  const updateOccasionsInSet = (value: OccasionInSet[]) => {
    occasionsInSet.value = value;
  };

  const updateOccasionsToAddInSet = (value: Occasion[]) => {
    occasionsToAddInSet.value = value;
  };

  const updateInitialSetValues = (value: InitialSetValues) => {
    initialSetValues.value = value;
  };

  const pushIntoGeoSearchValues = (value: string) => {
    geoSearchValues.value.push(value);
  };

  const isSetChanged = computed<boolean>(() => {
    return (
      initialSetValues.value.setName !== collapseSpacesAndTrim(inputOccasionSetName.value) ||
      !!restoreOccasions.value.length ||
      !!deleteOccasions.value.length ||
      inputOccasionSet.value.occasions.length !== occasionsInSet.value.length
    );
  });

  const preparedOccasionsInSet = computed(() => {
    if (!occasionsInSet.value.length) {
      return [];
    }

    return occasionsInSet.value.map((item) => {
      return {
        ...item.occasion,
        occasionInstanceId: item.occasionInstance?.details.occasion_instance_id,
        isSelected: occasionsSelectedInSet.value.find(
          (el) => el.occasionInstanceId === item.occasionInstance?.details.occasion_instance_id
        ),
      };
    });
  });

  const resetSelectedSet = () => {
    updateInputOccasionSet(cloneDeep(createNewOccasionSetItem));
    updateOccasionsInSet([]);
  };

  const openPanel = (key: number) => {
    openedPanels[key] = true;
  };

  const closePanel = (key: number) => {
    openedPanels[key] = false;
  };

  const activatePanel = (key: number) => {
    activePanels[key] = true;
  };

  const deactivatePanel = (key: number) => {
    activePanels[key] = false;
  };

  const showPanelToggle = (key: number) => {
    visibleCollapsePanelToggles[key] = true;
  };

  const hidePanelToggle = (key: number) => {
    visibleCollapsePanelToggles[key] = false;
  };

  const isPanelActive = (key: number): boolean => {
    return activePanels[key];
  };

  const isPanelOpened = (key: number): boolean => {
    return openedPanels[key];
  };

  const updateOccasionMapData = (value: OccasionMapData[]) => {
    occasionsMapData.value = value || [];
  };

  const toggleLeftPanel = () => {
    isLeftPanelCollapsed.value = !isLeftPanelCollapsed.value;
  };

  const updateSearchValues = (value: string[]) => {
    geoSearchValues.value = value;
  };

  const updateOccasionsSelectedInSet = (value: Occasion[]) => {
    occasionsSelectedInSet.value = value;
  };

  const pushSearchValues = (value: string) => {
    geoSearchValues.value.push(value);
  };

  const setIsOccasionSelectionMounted = (value: boolean) => {
    isOccasionSelectionMounted.value = value;
  };

  const setInitialSetValues = () => {
    if (occasionsInSet.value) {
      updateInitialSetValues({
        setName: inputOccasionSetName.value,
        occasions: getOccasionsFromSet(occasionsInSet.value),
      });
    }
  };

  const setInitialOccasionSet = () => {
    if (availableOccasionSets.value.length) {
      const defaultOccasionSet = availableOccasionSets.value[0];
      updateInputOccasionSet(defaultOccasionSet);
      updateInputOccasionSetName(defaultOccasionSet.name);
    } else {
      updateInputOccasionSet(createNewOccasionSetItem);
      updateInputOccasionSetName("");
    }

    const mappedOccasionsInSet = inputOccasionSet.value.occasions.map((occasionInSet: OccasionToSaveSet) => {
      const occasion = {
        name: occasionInSet.occasion_instance.details.request.name,
        reach: occasionInSet.occasion_instance.details.statistics.reach,
        occasionId: occasionInSet.occasion_instance.id,
        title: occasionInSet.occasion_instance.details.request.name,
        image: occasionInSet.occasion_instance.data.image_content,
        video: occasionInSet.occasion_instance.data.video_content,
      };

      occasionInSet.occasion_instance.data;
      const occasionInstance = occasionInSet.occasion_instance;

      return {
        occasion,
        occasionInstance,
      };
    });

    updateOccasionsInSet(mappedOccasionsInSet);
    setInitialSetValues();
  };

  const fetchAvailableOccasionSets = async (strategyId: string) => {
    const availableOccasionSetsData = await useOccasionsSetsRead({ strategy_id: strategyId });
    updateAvailableOccasionSets(
      availableOccasionSetsData.sort((a, b) => Intl.Collator().compare(a.name as string, b.name as string))
    );
  };

  const mapForCompare = (occasionInstance: OccasionInstancePostBody) => {
    return {
      name: occasionInstance.details.request.name,
      title: occasionInstance.details.request.name,
      geographies: occasionInstance.details.request.geographies,
      geographyFilter: occasionInstance.details.request.geography_filter,
      timeFilter: occasionInstance.details.request.time_filter,
      reach: occasionInstance.details.statistics.reach,
    };
  };

  const restoreOccasions = computed(() => {
    return inputOccasionSet.value.occasions.filter((inputOccasion) => {
      const occasionInSet = occasionsInSet.value.find((occasion) => {
        return inputOccasion.occasion_instance.id === occasion.occasionInstance.id;
      });

      if (
        occasionInSet &&
        isEqual(mapForCompare(occasionInSet.occasionInstance), mapForCompare(inputOccasion.occasion_instance))
      ) {
        return;
      }
      return occasionInSet;
    });
  });

  const deleteOccasions = computed(() => {
    return occasionsInSet.value.filter((occasion) => {
      return !inputOccasionSet.value.occasions.find((inputOccasion) => {
        return inputOccasion.occasion_instance.id === occasion.occasionInstance.id;
      });
    });
  });

  const addOccasionToSet = (instance: ResponsePostOccasion | null, occasion: Occasion | null) => {
    if (!occasion) {
      return;
    }

    if (!instance) {
      showToast({
        severity: "error",
        detail: lang.error.occasionWasNotAddedToSet(occasion.name),
      });

      return;
    }

    occasionsInSet.value.push({
      occasion,
      occasionInstance: {
        id: occasion.occasionId,
        details: instance,
        data: {
          name: occasion.name,
          reach: occasion.reach,
          image_content: occasion.image || "",
          video_content: occasion.video || "",
        },
      },
    });
  };

  return {
    occasions,
    occasionsMapData,
    occasionBeatData,
    isOccasionSelectionMounted,
    inputOccasionSet,
    availableOccasionSets,
    inputOccasionSetName,
    openedPanels,
    activePanels,
    visibleCollapsePanelToggles,
    isLeftPanelCollapsed,
    strategy,
    currentStrategy,
    geoSearchValues,
    relevantOccasions,
    selectedOccasion,
    occasionsInSet,
    occasionsSelectedInSet,
    occasionsToAddInSet,
    cleanApiResponse,
    initialSetValues,
    preparedOccasionsInSet,
    isSetChanged,
    restoreOccasions,
    deleteOccasions,
    updateOccasions,
    updateOccasionsMapData,
    updateOccasionBeatData,
    updateIsOccasionSelectionMounted,
    updateInputOccasionSet,
    updateAvailableOccasionSets,
    updateInputOccasionSetName,
    updateGeoSearchValues,
    updateRelevantOccasions,
    updateSelectedOccasion,
    updateOccasionsInSet,
    updateOccasionsSelectedInSet,
    updateOccasionsToAddInSet,
    updateInitialSetValues,
    isPanelOpened,
    isPanelActive,
    openPanel,
    closePanel,
    activatePanel,
    deactivatePanel,
    showPanelToggle,
    hidePanelToggle,
    toggleLeftPanel,
    pushIntoGeoSearchValues,
    updateOccasionMapData,
    updateSearchValues,
    pushSearchValues,
    setIsOccasionSelectionMounted,
    fetchAvailableOccasionSets,
    resetSelectedSet,
    setInitialOccasionSet,
    setInitialSetValues,
    addOccasionToSet,
  };
});
