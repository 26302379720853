export enum VueAppMode {
  Development = "development",
  Production = "production",
}

export enum NodeEnv {
  Development = "development",
  Test = "test",
  Production = "production",
}
