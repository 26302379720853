import { Ref, ref } from "vue";
import { decodeCredential } from "vue3-google-login";

import { useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { useAppStore } from "@/store/app/appStore";

export const MEDIA_TOKEN_KEY = "mediaToken";
const OFFSET_TIME = 5 * 60 * 1000;
const checkToken = (token: string | null): boolean => {
  if (!token) {
    return false;
  }

  const decodedToken = decodeCredential(token) as Record<string, string>;
  const expiresIn = Number(decodedToken.expires_in);
  return Number(decodedToken.created_at) + expiresIn >= Date.now() - OFFSET_TIME;
};

const getToken = async () => {
  const appStore = useAppStore();
  const { updateIsLoading } = appStore;
  const { request, data } = useApi<string>({
    path: API_ROUTES.MEDIA_TOKEN as string,
  });

  updateIsLoading(true);
  try {
    await request();

    if (data.value) {
      localStorage.setItem(MEDIA_TOKEN_KEY, data.value);
      return data.value;
    }
  } finally {
    updateIsLoading(false);
  }
};

let timerId: ReturnType<typeof setTimeout> | null = null;
const refToken = ref("");
const isLoading = ref(false);

export const useMediaTokenRead = async (isUpdate?: boolean): Promise<Ref<string>> => {
  if (isLoading.value) {
    return refToken;
  }
  refToken.value = localStorage.getItem(MEDIA_TOKEN_KEY) || "";
  if (timerId) {
    clearTimeout(timerId);
  }

  if (isUpdate || !refToken.value || !checkToken(refToken.value)) {
    isLoading.value = true;
    const token = await getToken();

    if (token) {
      refToken.value = token;
    }

    isLoading.value = false;
  }
  const decodedToken = decodeCredential(refToken.value) as Record<string, string>;
  const expiresIn = Number(decodedToken.created_at) + Number(decodedToken.expires_in) - Date.now() - OFFSET_TIME;

  timerId = setTimeout(async () => {
    await useMediaTokenRead(true);
  }, expiresIn);
  return refToken;
};
