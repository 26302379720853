import { storeToRefs } from "pinia";

import { OccasionInstance } from "@/helpers/mapping/mappingOccasion";
import { useGetDsp } from "@/hooks/useGetDsp";
import { usePaidMediaStore } from "@/store/paidMediaStore/paidMediaStore";
import { LinkedOccasionBody } from "@/types/linkedOccasion";
import { LinkedItem } from "@/types/occasion";

export const changeItemsStatus = async (
  item: LinkedItem,
  callback: (item: LinkedOccasionBody) => void,
  refreshTable = true
) => {
  const paidMediaStore = usePaidMediaStore();
  const { campaignParams } = storeToRefs(paidMediaStore);
  const { fetchLinkedItems } = paidMediaStore;

  const config: LinkedOccasionBody = {
    client: campaignParams.value.client?.id ?? "",
    brand: campaignParams.value.brand?.id ?? "",
    category: campaignParams.value.category?.id ?? "",
    occasion_id: item.occasionId,
    line_item_id: item?.lineItem?.id ?? "",
    occasion_instance_id: item.occasionInstanceId,
    job_id: item.jobId,
    dsp: useGetDsp(item?.lineItem?.id ? [item?.lineItem?.id] : []),
    occasion_instance: item.occasionInstance as OccasionInstance,
  };

  await callback(config);

  if (refreshTable) {
    await fetchLinkedItems();
  }
};
