import { ApiOccasion } from "@/helpers/mapping/mappingOccasion";
import { ResponsePostOccasion } from "@/hooks/apiHooks/occasion/useOccasionOccasionInstantiate";
import { CampaignActionName } from "@/settings/ownMedia";
import { ApiGeography, ApiTimeData } from "@/types/api";
import { CampaignStatus, PullCampaign, PullPropertyExtended } from "@/types/api/activationCampaignTypes";
import { ProductState } from "@/types/catalog";
import { Status } from "@/types/flowTypes";
import { MediaTypeOption } from "@/types/mediaPropertyTypes";
import { StrategiesPostResponse } from "@/types/strategyTypes";

export interface OwnMediaParams {
  selectedStrategy: StrategiesPostResponse | null;
  actionCampaign: string | null;
  campaign: PullCampaign;
  mediaType: MediaTypeOption | null;
  mediaProperty: PullPropertyExtended | null;
  product: ProductState | null;
}

export interface ApiSectionOccasion {
  occasion_instance: {
    id: string;
    reach: number;
  };
  default: boolean;
  priority: number;
  campaign_id: string;
}

export interface AvailableSection {
  id: string;
  name: string;
  property_id: number;
  url: string;
  linked_campaigns_occasion_instances: ApiSectionOccasion[];
}

export interface PullSection {
  id: string;
  property_id: string;
  name: string;
  url: string;
  description: string;
  keywords: string[];
  iab_categories: string[];
}

export interface EditableSection {
  id?: string;
  name: string | null;
  property_id: string | null;
  url: string | null;
  description: string | null;
  keywords: string | null;
  iab_categories: string[];
}

export interface ApiPullLinkedOccasionInstance {
  client: string;
  brand: string[];
  category: string;
  id: string;
  data: ApiOccasion;
  details: ResponsePostOccasion;
}

export interface LinkedSection {
  sectionId: string;
  sectionName: string;
  occasionName: string;
  occasionTitle: string;
  occasionDefault: boolean;
  occasionPriority: number;
  occasionRelevanceIndex?: number;
  occasionId: string;
  occasionInstanceId: string;
  occasionInstanceReach: number;
  campaignId: string;
  status: string;
  occasionInstance: ApiPullLinkedOccasionInstance;
  geographies: ApiGeography[];
  geographyFilter: ApiGeography[];
  timeFilter: ApiTimeData | null;
  weight: number;
}

export interface ApiPullLinkedOccasion {
  campaign_id: string;
  default: string;
  occasion_instance_ids: string[];
  occasion_instances: ApiPullLinkedOccasionInstance[];
  property_id: string;
  section_id: string;
  status: Status;
}

export const EMPTY_CAMPAIGN_OBJECT = {
  name: "",
  start_date: null,
  end_date: null,
  status: CampaignStatus.Inactive,
};

export interface ActionCampaign {
  name: string;
  action: CampaignActionName;
  disabled: boolean;
  selectedByDefault?: boolean;
}
