type ApiRoutes = Record<string, string | ((value: string | number) => string)>;

export const API_ROUTES: ApiRoutes = {
  SELECTED_OCCASION: "/v2/ui/occasions/selected",
  SELECTED_OCCASION_POST: "/v3/ui/occasions/selected",
  SELECTED_OCCASION_ALL: "/v2/ui/occasions/selected/all",
  CATALOG: "/v2/catalog",
  OCCASIONS_SEARCH: "/v2/library/occasions/search",
  OCCASION: "/v2/occasion",
  READY: "/v2/ready",
  ADVERTISERS_CATALOG: "/v2/advertizers/catalog",
  ADVERTISERS_DUPLICATES: "/v2/advertizers/duplicates",
  LINKED_OCCASION: "/v3/ui/occasions/linked",
  LINKED_OCCASION_POST: "/v3/ui/occasions/linked",
  MEDIA_TOKEN: "/v2/media/adobe/token",
  OCCASION_WITH_ID: (id) => `/v2/occasion/${id}`,
  OCCASION_CELLS: (occasionInstanceId) => `/v2/occasion/${occasionInstanceId}/cells`,
  OCCASION_BEAT: (occasionInstanceId) => `/v2/occasion/${occasionInstanceId}/beat`,
  JOB_STATUS: (id) => `/v2/job/${id}`,
  OCCASION_RECALL: (occasionInstanceId) => `/v2/occasion/${occasionInstanceId}/recall`,
  VALIDATE_IN_PLATFORM: (occasionInstanceId) => `/v2/occasion/${occasionInstanceId}/validate`,
  OCCASIONS_SDF: "/v2/occasions/sdf",
  PULL_PROPERTIES: "/v2/pull/properties",
  PULL_PROPERTIES_WITH_ID: (propertyId) => `/v2/pull/properties/${propertyId}`,
  PULL_SECTIONS: "/v2/pull/sections",
  PULL_SECTIONS_WITH_ID: (sectionId) => `/v2/pull/sections/${sectionId}`,
  PULL_LINKED: "/v3/pull/linked",
  PULL_CAMPAIGNS: "/v2/pull/campaigns",
  STRATEGIES: "/v2/ui/strategies",
  STRATEGIES_WITH_ID: (strategyId) => `/v2/ui/strategies/${strategyId}`,
  OCCASIONS_SETS: "/v2/ui/occasions_sets",
  OCCASIONS_SETS_DELETE: (occasion_instance_id) => `/v2/ui/occasions_sets/occasions/${occasion_instance_id}`,
  OCCASION_STRUCTURE: (occasionId) => `/v2/library/${occasionId}/structure`,
  OCCASIONS_SETS_ADD: (occasion_set_id) => `/v2/ui/occasions_sets/${occasion_set_id}/occasions`,
  OCCASION_SET_UPDATE: (occasions_set_id) => `/v2/ui/occasions_sets/${occasions_set_id}`,
  OCCASIONS_SETS_OCCASION_PUT: (occasionId) => `/v2/ui/occasions_sets/occasions/${occasionId}`,
  CAMPAIGNS: "/v2/ui/campaigns",
};
