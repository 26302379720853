export enum campaignSelectionField {
  selectedStrategy = "selectedStrategy",
  insertionOrder = "insertionOrder",
  campaignMain = "campaignMain",
  activationPlatform = "activationPlatform",
  campaign = "campaign",
}

export enum ActivationPlatform {
  Meta = "meta",
  Dv360 = "dv360",
}

export const NO_RESET_QUERY_NAME = "no_reset";

export enum PaidMediaCampaignActionName {
  Create = "create",
  Select = "select",
}
