import { MicroAppCustomProps } from "@wpp-open/core";
import { MicroAppContext } from "@wpp-open/core/src/types/microApp";
import { defineStore } from "pinia";
import { ref, watch } from "vue";

export const useWppOs = defineStore("wppOs", () => {
  const context = ref<MicroAppCustomProps["osContext"] | null>(null);
  const osContextValue = ref<MicroAppContext | null>(null);
  const isContextReady = ref(false);
  const osApi = ref<MicroAppCustomProps["osApi"] | null>(null);

  const updateOsContextValue = (value: MicroAppContext) => {
    osContextValue.value = value;
    isContextReady.value = true;
  };

  const initStore = (osContext: MicroAppCustomProps["osContext"], osApiProp: MicroAppCustomProps["osApi"]) => {
    osApi.value = osApiProp;
    context.value = osContext;
  };

  const waitContext = async (): Promise<void> => {
    return new Promise((resolve) => {
      if (isContextReady.value) {
        resolve();
        return;
      }
      watch(isContextReady, (val) => {
        if (val) {
          resolve();
        }
      });
    });
  };

  watch(context, (newContext) => {
    isContextReady.value = false;
    newContext?.subscribe(updateOsContextValue);
  });

  return {
    osApi,
    osContext: osContextValue,
    initStore,
    waitContext,
  };
});
