import { CatalogOccasionsFilters, ProductState } from "@/types/catalog";
import { StrategyValue } from "@/types/occasion";

export const DEFAULT_COUNTRY = "us";

export const initialEmptyProduct = { client: "", category: "", brand: [] } as ProductState;

export type OccasionSortType = "reach" | "title";
export type SortDirection = "asc" | "desc";

export interface OccasionSort {
  title: string;
  value: OccasionSortType;
}

export const OCCASIONS_SORTS: OccasionSort[] = [
  {
    title: "Reach",
    value: "reach",
  },
  {
    title: "Name",
    value: "title",
  },
];

export const defaultStrategyValue: StrategyValue = {
  productValues: { ...initialEmptyProduct },
  geographyValues: [],
  geographyApiValues: {},
  timeValues: null,
};

export const defaultFilterValues: CatalogOccasionsFilters = {};
