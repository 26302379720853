import { storeToRefs } from "pinia";

import { useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import lang from "@/i18n";
import { useAppStore } from "@/store/app/appStore";
import { useCatalogStore } from "@/store/catalog/catalogStore";
import { Catalog } from "@/types/catalog";

export enum CatalogMember {
  Products = "products",
  Geo = "geo",
  Occasions = "occasions",
}

export async function useCatalogRead(isForceUpdate?: boolean): Promise<Catalog>;
export async function useCatalogRead<K extends keyof Catalog>(isForceUpdate?: boolean, name?: K): Promise<Catalog[K]>;
export async function useCatalogRead<K extends keyof Catalog>(
  isForceUpdate?: boolean,
  name?: K
): Promise<Catalog | Catalog[K]> {
  const catalogStore = useCatalogStore();
  const { catalog, isFullCatalogLoaded } = storeToRefs(catalogStore);

  const appStore = useAppStore();
  const { showToast } = useAppStore();
  const { updateIsLoading } = appStore;

  if (name && catalog.value?.[name] && !isForceUpdate) {
    return catalog.value[name];
  }

  if (!name && isFullCatalogLoaded.value && !isForceUpdate) {
    return {
      products: catalog.value?.products,
      geo: catalog.value?.geo,
      occasions: catalog.value?.occasions,
    };
  }

  const reqConfig: { path: string; queryParams?: { members: string } } = {
    path: API_ROUTES.CATALOG as string,
  };

  if (name) {
    reqConfig.queryParams = {
      members: name,
    };
  }

  const { request, data } = useApi<Catalog>(reqConfig);

  updateIsLoading(true);

  try {
    await request();

    if (data.value?.products) {
      for (const product of data.value.products) {
        if (!product.dsp) {
          continue;
        }

        if (!Array.isArray(product.dsp)) {
          product.dsp = [product.dsp];
        }
      }
    }

    if (name) {
      catalogStore.setCatalogParam(name, data.value || {});
    } else {
      catalogStore.setCatalog(data.value || {});
    }
  } catch (e) {
    showToast({
      severity: "error",
      detail: lang.error.getCatalogs,
    });
  } finally {
    updateIsLoading(false);
  }

  return name
    ? catalog.value?.[name]
    : {
        products: catalog.value?.products,
        geo: catalog.value?.geo,
        occasions: catalog.value?.occasions,
      };
}
