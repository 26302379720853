import { cloneDeep } from "lodash";

import { ResponsePostOccasion } from "@/hooks/apiHooks/occasion/useOccasionOccasionInstantiate";
import { ApiGeography, ApiTimeData } from "@/types/api";
import { ResponseLinkedOccasion } from "@/types/linkedOccasion";
import { LineItem, LinkedItem, Occasion, OccasionInSet } from "@/types/occasion";
import { OccasionToSaveSet } from "@/types/occasionSetTypes";

export interface Statistics {
  cells_count: number;
  unique_h3_count: number;
  compacted_h3_count: number;
  compacted_h3_levels: Record<string, number>;
  reach: number;
  occasion_instance_items_count?: number;
  duplication_level: number;
}

export interface OccasionInstanceItem {
  occasion_instance_item_id: string;
  statistics: Statistics;
  dsp_id: Record<string, unknown>[];
  dsp_options: Record<string, unknown>;
}

export interface ApiOccasion {
  name: string;
  description?: string;
  snappy_name?: string;
  snappy_name_short?: string;
  reach: number;
  image_content: string;
  video_content: string;
}

export interface ApiStoredOccasion {
  client?: string;
  brand?: string[];
  category?: string;
  occasion_id: string;
  occasion_instance_id: string;
  occasion_instance: OccasionInstance;
}

export interface OccasionInstance {
  client?: string;
  brand?: string[];
  category?: string;
  id: string;
  data: ApiOccasion;
  details: ResponsePostOccasion;
  geographies: ApiGeography[];
  geographyFilter: ApiGeography[];
  timeFilter: ApiTimeData;
  reach: number;
}

export const cleanTitle = (title: string): string => {
  return title ? title.replace(/\s+/g, " ") : "";
};

export const prepareOccasionByApiOccasion = (key: string, apiOccasion: ApiOccasion): Occasion => {
  return {
    occasionId: key,
    name: apiOccasion.name,
    snappyNameShort: apiOccasion.snappy_name_short,
    title: cleanTitle(apiOccasion.name),
    reach: apiOccasion.reach,
    image: apiOccasion.image_content,
    video: apiOccasion.video_content,
  };
};

export const prepareOccasionByOccasionInstance = (key: string, occasionInstance: OccasionInstance): Occasion => {
  const {
    name,
    snappy_name_short: snappyNameShort,
    image_content: image,
    video_content: video,
  } = (occasionInstance.data ?? occasionInstance) || {};

  const occasionInstanceName = occasionInstance.details?.request?.name;

  const reach = occasionInstance?.details ? occasionInstance.details.statistics?.reach : occasionInstance?.reach;
  const { geographies, client, brand, category } = occasionInstance ?? {};

  return {
    occasionId: key,
    name,
    snappyNameShort,
    title: cleanTitle(occasionInstanceName ?? name),
    reach,
    image,
    video,
    geographies,
    client,
    brand,
    category,
  };
};

export const prepareExtendedOccasionInstance = (key: string, apiStoredData: ApiStoredOccasion): Occasion => {
  const { occasion_instance_id: occasionInstanceId, occasion_instance: occasionInstance } = apiStoredData || {};

  return {
    ...prepareOccasionByOccasionInstance(key, occasionInstance),
    occasionInstanceId,
    occasionInstance,
    duplicationLevel: occasionInstance?.details?.statistics?.duplication_level as number,
  };
};

export const responseLinkedToLinkedItem = (linkedOccasion: ResponseLinkedOccasion, lineItem?: LineItem): LinkedItem => {
  return {
    id: linkedOccasion.occasion_instance.id,
    occasionId: linkedOccasion.occasion_id,
    occasionInstanceId: linkedOccasion.occasion_instance_id,
    geographies: linkedOccasion.occasion_instance?.details?.request?.geographies?.geographies,
    geographyFilter: linkedOccasion.occasion_instance?.details?.request?.geography_filter,
    timeFilter: linkedOccasion.occasion_instance?.details?.request?.time_filter,
    weight: linkedOccasion.occasion_instance?.details?.request?.weight,
    name: linkedOccasion.occasion_instance?.details.request.name,
    snappyNameShort: linkedOccasion.occasion_instance?.data?.snappy_name_short,
    title: linkedOccasion.occasion_instance?.details.request.name,
    occasionInstance: linkedOccasion.occasion_instance,
    lineItem: cloneDeep(lineItem),
    duplicationLevel: linkedOccasion.occasion_instance.details.statistics.duplication_level,
    reach: linkedOccasion.occasion_instance?.details.statistics.reach,
    status: linkedOccasion.status,
    jobId: linkedOccasion.occasion_instance?.details.job_id,
  };
};

export const getOccasionsFromSet = (occasions: OccasionInSet[]) => {
  return occasions.map((occasion) => occasion.occasion);
};

export const occasionToSaveSetToApiStoredOccasion = (occasionToSaveSet: OccasionToSaveSet) => {
  const { occasion_id, occasion_instance_id, occasion_instance } = occasionToSaveSet;
  const { request, statistics } = occasion_instance.details || {};

  return {
    occasion_id,
    occasion_instance_id,
    occasion_instance: {
      ...occasion_instance,
      geographies: request?.geographies?.geographies,
      geographyFilter: request?.geography_filter,
      timeFilter: request?.time_filter,
      reach: statistics.reach,
    },
  } as ApiStoredOccasion;
};

export const mapOccasionToSaveSetToOccasionInSet = (occasionToSaveSet: OccasionToSaveSet): OccasionInSet => {
  const occasion = {
    name: occasionToSaveSet.occasion_instance.details.request.name,
    reach: occasionToSaveSet.occasion_instance.details.statistics.reach,
    occasionId: occasionToSaveSet.occasion_instance.id,
    title: occasionToSaveSet.occasion_instance.details.request.name,
    image: occasionToSaveSet.occasion_instance.data.image_content,
    video: occasionToSaveSet.occasion_instance.data.video_content,
  };

  occasionToSaveSet.occasion_instance.data;
  const occasionInstance = occasionToSaveSet.occasion_instance;

  return {
    occasion,
    occasionInstance,
  };
};

export const mapGeoSearchValueToApiGeography = (geoSearchValue: string): ApiGeography => {
  return {
    city: [],
    country: [geoSearchValue],
    postal_code: [],
    region_1: [],
    region_2: [],
  };
};
