import { storeToRefs } from "pinia";
import { RouteRecordRaw } from "vue-router";

import { ROUTES } from "@/constants/routes";
import router from "@/router/index";
import { useOwnMediaStore } from "@/store/ownMedia/ownMediaStore";
import { usePaidMediaStore } from "@/store/paidMediaStore/paidMediaStore";
import { VueAppMode } from "@/types/env";
import DevelopmentPage from "@/views/DevelopmentPage.vue";

const LoginView = () => import("@/views/LoginView.vue");
const ExploreOccasion = () => import("@/views/OccasionExplorer.vue");
const CampaignSelection = () => import("@/views/CampaignSelection.vue");
const ManageOwnMedia = () => import("@/views/ManageOwnMedia.vue");
const MyOccasions = () => import("@/views/MyOccasions.vue");
const MyMediaProperties = () => import("@/views/MyMediaProperties.vue");
const LinkingSections = () => import("@/views/LinkingSections.vue");
const LinkingPlacements = () => import("@/views/LinkingPlacements.vue");
const HomeView = () => import("@/views/HomeView.vue");

export const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTES.LOGIN.PATH,
    name: ROUTES.LOGIN.NAME,
    component: LoginView,
  },

  {
    path: ROUTES.ROOT.PATH,
    name: ROUTES.ROOT.NAME,
    component: HomeView,
  },

  {
    path: ROUTES.CAMPAIGN_SELECTION.PATH,
    name: ROUTES.CAMPAIGN_SELECTION.NAME,
    component: CampaignSelection,
  },

  {
    path: ROUTES.LINKING_OCCASIONS.PATH,
    name: ROUTES.LINKING_OCCASIONS.NAME,
    component: LinkingPlacements,
    beforeEnter: async () => {
      const paidMediaStore = usePaidMediaStore();
      const { campaignParams } = storeToRefs(paidMediaStore);

      if (!campaignParams.value.client) {
        await router.push({ path: ROUTES.CAMPAIGN_SELECTION.PATH });
      }
    },
  },

  {
    path: ROUTES.EXPLORE_OCCASION.PATH,
    name: ROUTES.EXPLORE_OCCASION.NAME,
    component: ExploreOccasion,
  },

  {
    path: ROUTES.MY_SAVED_OCCASIONS.PATH,
    name: ROUTES.MY_SAVED_OCCASIONS.NAME,
    component: MyOccasions,
  },

  {
    path: ROUTES.LINKING_SECTIONS.PATH,
    name: ROUTES.LINKING_SECTIONS.NAME,
    component: LinkingSections,
    beforeEnter: async () => {
      const ownMediaStore = useOwnMediaStore();
      const { ownMediaParams } = storeToRefs(ownMediaStore);
      if (!ownMediaParams.value.mediaType) {
        await router.push({ path: ROUTES.MANAGE_OWN_MEDIA.PATH });
      }
    },
  },

  {
    path: ROUTES.MANAGE_OWN_MEDIA.PATH,
    name: ROUTES.MANAGE_OWN_MEDIA.NAME,
    component: ManageOwnMedia,
  },

  {
    path: ROUTES.MY_MEDIA_PROPERTIES.PATH,
    name: ROUTES.MY_MEDIA_PROPERTIES.NAME,
    component: MyMediaProperties,
  },

  {
    path: ROUTES.DEVELOPMENT.PATH,
    name: ROUTES.DEVELOPMENT.NAME,
    component: DevelopmentPage,
    beforeEnter: async () => {
      if (process.env.VUE_APP_MODE !== VueAppMode.Development) {
        await router.push({ path: ROUTES.ROOT.PATH });
      }
    },
  },
];
