import { defineStore } from "pinia";
import { ref } from "vue";

import { useAppStore } from "@/store/app/appStore";
import { AdvertizerCatalog, Campaign, Catalog } from "@/types/catalog";

export const useCatalogStore = defineStore("catalogStore", () => {
  const catalog = ref<Catalog>({});
  const isFullCatalogLoaded = ref(false);

  const advertizerCatalog = ref<AdvertizerCatalog>({
    campaigns: [],
  });

  const app = useAppStore();

  const updateCatalog = (value: Catalog) => {
    catalog.value = value;
  };

  const updateIsFullCatalogLoaded = (value: boolean) => {
    isFullCatalogLoaded.value = value;
  };

  const updateAdvertizerCatalog = (value: AdvertizerCatalog) => {
    advertizerCatalog.value = value;
  };

  const setCatalog = (data: Catalog) => {
    app.updateIsLoading(false);
    updateCatalog(data);
    updateIsFullCatalogLoaded(true);
  };

  const setCatalogParam = (param: keyof Catalog, data: Catalog) => {
    (catalog.value[param] as Catalog[typeof param]) = data[param];
  };

  const setAdvertizerCatalogCompaigns = (campaigns: Campaign[]) => {
    advertizerCatalog.value.campaigns = campaigns;
  };

  return {
    catalog,
    isFullCatalogLoaded,
    advertizerCatalog,
    updateCatalog,
    updateIsFullCatalogLoaded,
    updateAdvertizerCatalog,
    setCatalog,
    setCatalogParam,
    setAdvertizerCatalogCompaigns,
  };
});
