import { Config, RequestMethod, useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { useAppStore } from "@/store/app/appStore";
import { ApiPullLinkedOccasion } from "@/types/ownMediaTypes";
import { SelectedOccasionConfig } from "@/types/selectedOccasionTypes";

export const usePullLinkedOccasionsRead: <P extends SelectedOccasionConfig>(
  config: P
) => Promise<ApiPullLinkedOccasion[]> = async (config) => {
  const appStore = useAppStore();
  const { updateIsLoading, showToast } = appStore;

  const apiConfig: Config = {
    path: API_ROUTES.PULL_LINKED as string,
    method: RequestMethod.Get,
    queryParams: config.params,
  };

  const { request, data } = useApi<ApiPullLinkedOccasion[]>(apiConfig);

  updateIsLoading(true);
  try {
    await request();

    if (data.value) {
      return data.value;
    }

    if (config.successMessage) {
      showToast({
        severity: "success",
        detail: config.successMessage,
      });
    }
  } catch (e) {
    const errorMessage = config.errorMessage;

    if (errorMessage) {
      showToast({
        severity: "error",
        detail: errorMessage,
      });
    }
  } finally {
    updateIsLoading(false);
  }

  return [];
};
