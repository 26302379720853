import { Lang } from "@/types/lang";

export default <Lang>{
  checkLinkedOccasions: "Linked occasion update failed",
  deleteSection: "Section cannot be deleted",
  deleteSavedOccasion: "Failed delete saved occasion",
  deleteSavedLinkedOccasion: "This occasions cannot be deleted because it is linked.",
  getCatalogs: "Failed to get the catalogs",
  getLinkedOccasions: "Failed to get the linked occasions",
  getOccasionCells: "Failed to get the occasion cells",
  getOccasionBeat: "Failed to get the occasion beat",
  getDuplicates: "Failed to load duplicates",
  linkOccasion: "Occasion was not linked",
  loadSavedOccasions: "Failed to load saved occasions",
  mediaPropertyIsStillLinked: "Media Property was not deleted, because it is still linked.",
  occasionIsNotValid: "Invalid occasion data",
  occasionNotCreatedProperly: "Occasion was not created properly",
  pushedOccasions: "Occasion wasn't pushed",
  pushSdfGenerating: "Push SDF wasn't generated",
  pushStartedOccasions: "Occasion push has not been started",
  pushSdfGeneratingStarted: "Generating Push SDF has not been started",
  recallNotStarted: "Occasion recall has not been started",
  recallSdfStarted: "Generating Recall SDF has not been started",
  recallNotFinished: "Occasion recall has not been finished",
  recallSdfFinished: "Recall SDF wasn't generated",
  saveOccasion: "Failed to save the occasion",
  somethingWentWrong: "Something went wrong",
  sectionHasLinkedOccasions: "The section has one or more linked occasions.",
  updateLinkedOccasion: "Occasion wasn't updated",
  campaignWithSameNameAlreadyExists: "Campaign with this name already exists.",
  strategyWithSameNameAlreadyExists: "Strategy with the same name already exists.",
  occasionSetWithSameNameAlreadyExists: "Occasions set with the same name already exists.",
  occasionsSetOccasionDeletedError: "Occasion deletion has not been finished",
  occasionNameRequired: "Occasion name is required",
  somethingWentWrongVideo: "Something went wrong, please check the properties of the video",
  occasionWasNotAddedToSet: (name: string) => `Occasion '${name}' was not added to set`,
};
