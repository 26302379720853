import { CatalogTreeSelectOption, TreeOptionsData } from "@/helpers/mapping/mappingCatalog";
import { FilterKey } from "@/settings/exploreOccasion";
import { CampaignActionName } from "@/settings/ownMedia";
import { ApiCatalogDsp } from "@/types/api";
import { PullCampaign } from "@/types/api/activationCampaignTypes";
import { LineItem } from "@/types/occasion";
import { StrategiesPostResponse } from "@/types/strategyTypes";

export interface ProductState {
  client: string | null;
  category: string;
  brand: string[];
}

export interface ProductBrand {
  id: string;
  name: string;
}

export interface ProductCategory {
  id: string;
  name: string;
  brands: ProductBrand[];
}

export interface Product extends TreeOptionsData {
  dsp: {
    advertiserId: string;
    dsp_type: string;
  }[];
  categories: ProductCategory[];
}

export interface CatalogSimpleOption {
  id: string;
  name: string;
}

export interface CatalogDayPartOption extends CatalogSimpleOption {
  start: number;
  end: number;
}

export interface CatalogWeekDayOption extends CatalogSimpleOption {
  values: string[];
}

export interface MacroActivity extends TreeOptionsData {
  macro_occasions: CatalogSimpleOption[];
}

export interface PlaceOption {
  id: string;
  name: string;
}

export interface CatalogOccasions {
  [FilterKey.Categories]?: CatalogSimpleOption[];
  [FilterKey.Consumptions]?: CatalogSimpleOption[];
  [FilterKey.DaysOfWeek]?: CatalogWeekDayOption[];
  [FilterKey.DayParts]?: CatalogDayPartOption[];
  [FilterKey.Keywords]?: string[];
  [FilterKey.MacroActivities]?: MacroActivity[];
  [FilterKey.MacroOccasions]?: string[];
  [FilterKey.Places]?: PlaceOption[];
  [FilterKey.Pois]?: Record<string, unknown>[];
}

export interface CatalogOccasionsFilters {
  [FilterKey.Categories]?: string[];
  [FilterKey.Consumptions]?: string[];
  [FilterKey.Keywords]?: string[];
  [FilterKey.MacroActivities]?: CatalogTreeSelectOption[];
  [FilterKey.MacroOccasions]?: string[];
  [FilterKey.Places]?: string[];
}

export interface Catalog {
  products?: Product[];
  geo?: Record<string, Record<string, string[]>>;
  occasions?: CatalogOccasions;
}

export type AdvertizerCatalog = {
  campaigns: Campaign[];
};

export type InsertionOrder = {
  id: string;
  name: string;
  lineitems: LineItem[];
};

export interface Campaign {
  id: string;
  key?: string;
  name: string;
  start_date: string;
  end_date: string;
  insertion_orders: InsertionOrder[];
}

export interface CampaignObjectParam {
  id: string;
  name: string;
  dsp?: ApiCatalogDsp[];
  lineitems?: LineItem[];
  start_date?: string;
  end_date?: string;
  value?: Record<string, string>;
}

export enum CampaignParamsKey {
  ActionCampaign = "actionCampaign",
  ActivationPlatform = "activationPlatform",
  Brand = "brand",
  Campaign = "campaign",
  CampaignMain = "campaignMain",
  Category = "category",
  Client = "client",
  InsertionOrder = "insertionOrder",
  Product = "product",
  SelectedStrategy = "selectedStrategy",
}

export interface CampaignParams {
  [CampaignParamsKey.ActionCampaign]: CampaignActionName | null;
  [CampaignParamsKey.ActivationPlatform]: CampaignObjectParam | null;
  [CampaignParamsKey.Brand]: CampaignObjectParam | null;
  [CampaignParamsKey.Campaign]: CampaignObjectParam | null;
  [CampaignParamsKey.CampaignMain]: PullCampaign | null;
  [CampaignParamsKey.Category]: CampaignObjectParam | null;
  [CampaignParamsKey.Client]: CampaignObjectParam | null;
  [CampaignParamsKey.InsertionOrder]: CampaignObjectParam | null;
  [CampaignParamsKey.Product]: ProductState | null;
  [CampaignParamsKey.SelectedStrategy]: StrategiesPostResponse | null;
}
