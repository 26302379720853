import { MediaTypeOption } from "@/types/mediaPropertyTypes";

export interface PullActivationCampaignOccasion {
  id: string;
  default: boolean;
}

export interface PullSectionShort {
  id: string;
  name: string;
}

export interface PullProperty {
  id: string;
  type: string;
  name: string;
  domain: string;
  description: string;
  keywords: string[];
  iab_categories: string[];
  endpoint: string;
}

export interface PullPropertyExtended extends PullProperty {
  sections: PullSectionShort[];
}

export interface PreparedPullProperty extends PullProperty {
  // TODO: Remove string[] type when options will be array of objects.
  sections: MediaTypeOption[] | string[];
}

export type PreparedPullPropertyKey = keyof PreparedPullProperty;
export type PreparedPullPropertyValue = PreparedPullProperty[PreparedPullPropertyKey];

export type PullActivationCampaign = {
  id: string;
  name: string;
  start_date?: string;
  end_date?: string | null;
  paused?: boolean;
  paid_media_campaign_id?: string | null;
  occasions?: PullActivationCampaignOccasion[];
};

export enum CampaignStatus {
  Active = "active",
  Inactive = "inactive",
}

export interface PullCampaign {
  id?: string;
  name: string | null;
  start_date: string | null;
  end_date: string | null;
  status: CampaignStatus;
  priority?: number;
  platforms?: string[];
}

export interface ApiPullCampaign {
  id: string;
  name: string;
  start_date: string;
  end_date: string | null;
  status: CampaignStatus;
  platforms?: string[];
  priority?: number;
  strategy_id: string;
}
