import { ROUTES } from "@/constants/routes";
import lang from "@/i18n";

export const navigation = [
  {
    title: lang.title.defineOccasionStrategy,
    path: ROUTES.EXPLORE_OCCASION.PATH,
  },
  {
    title: lang.title.activateInPaidMedia,
    path: ROUTES.CAMPAIGN_SELECTION.PATH,
  },
  {
    title: lang.title.activateInOwnedMedia,
    path: ROUTES.MANAGE_OWN_MEDIA.PATH,
  },
];
