import { useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import lang from "@/i18n";
import { useAppStore } from "@/store/app/appStore";
import { PullSection } from "@/types/ownMediaTypes";

export const usePullSectionsRead = async (propertyId: string): Promise<PullSection[]> => {
  const appState = useAppStore();
  const { updateIsLoading, showToast } = appState;
  const { request, data } = useApi<PullSection[]>({
    path: API_ROUTES.PULL_SECTIONS as string,
    queryParams: {
      property_id: propertyId,
    },
  });

  updateIsLoading(true);

  try {
    await request();

    return data.value ?? [];
  } catch (e) {
    showToast({
      summary: lang.message.availableSections,
      severity: "error",
      detail: lang.error.somethingWentWrong,
    });
  } finally {
    updateIsLoading(false);
  }

  return [];
};
