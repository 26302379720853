import { cloneDeep } from "lodash";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";

import { initialEmptyProduct } from "@/constants/occasions";
import { prepareLinkedSectionsData } from "@/helpers/mapping/mappingLinkedSections";
import { ApiStoredOccasion, prepareExtendedOccasionInstance } from "@/helpers/mapping/mappingOccasion";
import { usePullCampaignsRead } from "@/hooks/apiHooks/pull/campaigns/usePullCampaignsRead";
import { usePullLinkedOccasionsRead } from "@/hooks/apiHooks/pull/linked/usePullLinkedOccasionsRead";
import { usePullSectionsRead } from "@/hooks/apiHooks/pull/sections/usePullSectionsRead";
import { ApiPullCampaign } from "@/types/api/activationCampaignTypes";
import { Occasion, OccasionMediaItem } from "@/types/occasion";
import {
  ApiPullLinkedOccasion,
  EMPTY_CAMPAIGN_OBJECT,
  LinkedSection,
  OwnMediaParams,
  PullSection,
} from "@/types/ownMediaTypes";

export const useOwnMediaStore = defineStore("ownMediaStore", () => {
  const campaigns = ref<ApiPullCampaign[]>([]);
  const ownMediaParams = reactive<OwnMediaParams>({
    selectedStrategy: null,
    product: initialEmptyProduct,
    actionCampaign: null,
    campaign: EMPTY_CAMPAIGN_OBJECT,
    mediaType: null,
    mediaProperty: null,
  });
  const isCreateCampaignFinished = ref(false);
  const availableOccasions = ref<Occasion[]>([]);
  const availableSections = ref<PullSection[]>([]);

  const linkedOccasions = ref<ApiPullLinkedOccasion[]>([]);
  const linkedSections = ref<LinkedSection[]>([]);
  const drawerItem = ref<LinkedSection | null>(null);
  const isDrawerVisible = ref(false);
  const isDrawerInViewMode = ref(false);
  const currentSelectedSection = ref<PullSection | null>(null);
  const sectionSearchValue = ref("");
  const isApproximationPopupOpened = ref(false);

  const currentSelectedOccasions = computed<Occasion[]>(() =>
    availableOccasions.value.filter((item) => item.isSelected)
  );

  const preparedAvailableOccasions = computed<OccasionMediaItem[]>(() =>
    availableOccasions.value.map((item) => {
      const isLinked = linkedSections.value.find(
        // TODO: Check and fix this after we implement new UI sceens based on new API.
        // TODO: Probably here we need to use the same comparison logic as for Occasion Set (Alex D).
        // OUTDATED:
        // TODO: Probably, it's not correct to find occasion by id here (correct is to find by instanceId).
        // TODO: But now we need it - to solve the problem with unexpected occasionInstanceId changes.
        (el) => el.sectionId === currentSelectedSection.value?.id && el.occasionId === item.occasionId
      );
      return isLinked ? { ...item, disabled: true } : item;
    })
  );

  const updateCampaigns = (value: ApiPullCampaign[]) => {
    campaigns.value = value;
  };

  const updateIsCreateCampaignFinished = (value: boolean) => {
    isCreateCampaignFinished.value = value;
  };

  const updateAvailableOccasions = (value: Occasion[]) => {
    availableOccasions.value = value;
  };

  const updateAvailableSections = (value: PullSection[]) => {
    availableSections.value = value;
  };

  const updateLinkedOccasions = (value: ApiPullLinkedOccasion[]) => {
    linkedOccasions.value = value;
  };

  const updateLinkedSections = (value: LinkedSection[]) => {
    linkedSections.value = value;
  };

  const updateDrawerItem = (value: LinkedSection) => {
    drawerItem.value = cloneDeep(value);
  };

  const updateIsDrawerVisible = (value: boolean) => {
    isDrawerVisible.value = value;
  };

  const updateIsDrawerInViewMode = (value: boolean) => {
    isDrawerInViewMode.value = value;
  };

  const updateCurrentSelectedSection = (value: PullSection | null) => {
    currentSelectedSection.value = value;
  };

  const updateSectionSearchValue = (value: string) => {
    sectionSearchValue.value = value;
  };

  const updateIsApproximationPopupOpened = (value: boolean) => {
    isApproximationPopupOpened.value = value;
  };

  const fetchCampaigns = async () => {
    if (ownMediaParams?.selectedStrategy?.id) {
      const apiCampaigns: ApiPullCampaign[] = await usePullCampaignsRead({
        strategy_id: ownMediaParams.selectedStrategy.id,
      });
      campaigns.value = cloneDeep(apiCampaigns);
    }
  };

  const setDefaultCampaign = () => {
    ownMediaParams.campaign = cloneDeep(campaigns.value[0] ?? EMPTY_CAMPAIGN_OBJECT);
  };

  const setNewCampaign = () => {
    ownMediaParams.campaign = cloneDeep(EMPTY_CAMPAIGN_OBJECT);
  };

  const updateSelectedOccasion = (item: Occasion) => {
    const targetItem = availableOccasions.value.find(
      (occasion) => occasion.occasionId === item.occasionId && occasion.occasionInstanceId === item.occasionInstanceId
    );
    if (targetItem) {
      targetItem.isSelected = !targetItem.isSelected;
    }
  };

  const updateOccasion = (occasions: ApiStoredOccasion[]) => {
    const occasionsArray: Occasion[] = occasions.map((item) => {
      const { occasion_id: key } = item;
      return prepareExtendedOccasionInstance(key, item);
    });

    updateAvailableOccasions(occasionsArray);
  };

  const fetchLinkedSections = async () => {
    const propertyId = ownMediaParams?.mediaProperty?.id;
    const campaignId = ownMediaParams?.campaign?.id;

    if (!propertyId || !campaignId) {
      return;
    }

    updateAvailableSections(await usePullSectionsRead(propertyId));
    updateLinkedOccasions(
      await usePullLinkedOccasionsRead({
        params: {
          campaign_id: campaignId,
          property_id: propertyId,
        },
      })
    );
    updateLinkedSections(prepareLinkedSectionsData(availableSections.value, linkedOccasions.value));

    if (currentSelectedSection.value) {
      const updatedSection = availableSections.value.find((item) => item.id === currentSelectedSection.value?.id);
      updateCurrentSelectedSection(updatedSection ?? null);
    }
  };

  const clearSelectedAvailableOccasions = (): void => {
    const clearedSelectedAvailableOccasions = availableOccasions.value.map((occasion) => {
      return { ...occasion, isSelected: false };
    });
    updateAvailableOccasions(clearedSelectedAvailableOccasions);
  };

  const resetOwnMediaParams = (): void => {
    ownMediaParams.campaign = cloneDeep(EMPTY_CAMPAIGN_OBJECT);
  };

  return {
    campaigns,
    ownMediaParams,
    availableOccasions,
    preparedAvailableOccasions,
    availableSections,
    currentSelectedOccasions,
    currentSelectedSection,
    sectionSearchValue,
    linkedSections,
    linkedOccasions,
    isDrawerInViewMode,
    isDrawerVisible,
    drawerItem,
    isCreateCampaignFinished,
    isApproximationPopupOpened,

    updateCampaigns,
    updateIsCreateCampaignFinished,
    updateAvailableOccasions,
    updateAvailableSections,
    updateLinkedOccasions,
    updateLinkedSections,
    updateDrawerItem,
    updateIsDrawerVisible,
    updateIsDrawerInViewMode,
    updateCurrentSelectedSection,
    updateSectionSearchValue,
    updateIsApproximationPopupOpened,
    setDefaultCampaign,
    fetchCampaigns,
    updateSelectedOccasion,
    fetchLinkedSections,
    clearSelectedAvailableOccasions,
    updateOccasion,
    resetOwnMediaParams,
    setNewCampaign,
  };
});
