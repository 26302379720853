import { Lang } from "@/types/lang";

export default <Lang>{
  addDates: "Select Date Range",
  dayParts: "Day Parts",
  select: "Select",
  searchLineItems: "Search Placements",
  searchSections: "Search Sections",
  searchMedia: "Search Media",
  searchOccasions: "Search Occasions",
  selectedAll: "All",
  typeCampaignName: "Type Campaign Name...",
  typeMediaProperty: "Type Media Property Name...",
  typeDomain: "Type Domain Address...",
  typeDescription: "Type Description...",
  typeKeywords: "Type Keywords/Labels...",
  typeEndpoint: "Type Endpoint URL...",
  weekDay: "Week Day",
  typeStrategyName: "Type Strategy Name...",
  typeOccasionSetName: "Type Occasions Set Name...",
  selectOccasionsSet: "Select Occasions Set",
};
