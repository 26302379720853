import { RequestMethod, useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import lang from "@/i18n";
import { useAppStore } from "@/store/app/appStore";
import { usePaidMediaStore } from "@/store/paidMediaStore/paidMediaStore";
import { Status, YoutubeStatus } from "@/types/flowTypes";
import { LinkedOccasionBody, LinkedOccasionMessage, ResponseLinkedOccasion } from "@/types/linkedOccasion";

export const useOccasionsLinkedOccasionLink = (
  status: Status | YoutubeStatus | null,
  defaultMessage: LinkedOccasionMessage
) => {
  return async (
    body: LinkedOccasionBody,
    message?: LinkedOccasionMessage,
    config?: {
      noToast?: boolean;
      noLoader?: boolean;
      noRefreshData?: boolean;
    }
  ): Promise<ResponseLinkedOccasion | null> => {
    const appStore = useAppStore();
    const { updateIsLoading, showToast } = appStore;

    const paidMediaStore = usePaidMediaStore();
    const { fetchLinkedItems } = paidMediaStore;

    const preparedBody = {
      ...body,
    };

    if (status) {
      preparedBody.status = status;
    }

    const { request, data } = useApi<ResponseLinkedOccasion>({
      body: preparedBody,
      method: RequestMethod.Post,
      path: API_ROUTES.LINKED_OCCASION as string,
    });

    if (!config?.noLoader) {
      updateIsLoading(true);
    }

    try {
      await request();
      if (!config?.noRefreshData) {
        await fetchLinkedItems();
      }
      if (!config?.noToast) {
        showToast({
          detail: message?.successMessage ?? defaultMessage.successMessage,
          severity: defaultMessage?.successMessageSeverity ?? "success",
        });
      }

      if (data.value) {
        return data.value;
      }
    } catch (e) {
      if (!config?.noToast) {
        showToast({
          severity: "error",
          detail: message?.errorMessage || defaultMessage.errorMessage || lang.error.somethingWentWrong,
        });
      }
    } finally {
      if (!config?.noLoader) {
        updateIsLoading(false);
      }
    }

    return null;
  };
};
