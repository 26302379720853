import { cloneDeep, uniq } from "lodash";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";

import { standardFlowTrigger } from "@/flows/standard/standardFlowTrigger";
import { youtubeFlowTrigger } from "@/flows/youtube/youtubeFlowTrigger";
import { responseLinkedToLinkedItem } from "@/helpers/mapping/mappingOccasion";
import { changeItemsStatus } from "@/helpers/tables/linkedOccasions/flows";
import { useCampaignsRead } from "@/hooks/apiHooks/campaigns/useCampaignsRead";
import { JobResponseBody, useJobStatusRead } from "@/hooks/apiHooks/job/useJobStatusRead";
import { useOccasionsLinkedOccasionsRead } from "@/hooks/apiHooks/occasions/linked/useOccasionsLinkedOccasionsRead";
import { useOccasionsSetsRead } from "@/hooks/apiHooks/occasionsSets/useOccasionsSetsRead";
import lang from "@/i18n";
import { CampaignActionName } from "@/settings/ownMedia";
import { useAppStore } from "@/store/app/appStore";
import { OccasionExtendedInfo } from "@/types";
import { ApiDuplicate } from "@/types/api";
import { ApiPullCampaign, PullCampaign } from "@/types/api/activationCampaignTypes";
import {
  Campaign,
  CampaignObjectParam,
  CampaignParams,
  CampaignParamsKey,
  InsertionOrder,
  Product,
  ProductBrand,
  ProductCategory,
} from "@/types/catalog";
import { JobOperationStatus, JobStatus, Status, YoutubeStatus } from "@/types/flowTypes";
import { LinkedOccasionParams, ResponseLinkedOccasion } from "@/types/linkedOccasion";
import { LineItem, LinkedItem, Occasion, OccasionDrawerItem } from "@/types/occasion";
import { OccasionsSetPostResponse } from "@/types/occasionSetTypes";
import { ActivationPlatformOption } from "@/types/paidMediaTypes";
import { DataItem } from "@/types/table";

const COUNT_JOB_REQUEST_ATTEMPTS = 3;

export const usePaidMediaStore = defineStore("paidMediaStore", () => {
  const campaignParams = reactive<CampaignParams>({
    client: null,
    category: null,
    activationPlatform: null,
    campaign: null,
    insertionOrder: null,
    selectedStrategy: null,
    product: null,
    brand: null,
    actionCampaign: null,
    campaignMain: null,
  });

  const appStore = useAppStore();
  const { showToast } = appStore;

  const availableLineItems = ref<LineItem[]>([]);

  const availableOccasions = ref<Occasion[]>([]);
  const availableOccasionSets = ref<OccasionsSetPostResponse[]>([]);

  const currentSelectedOccasion = computed<Occasion | null>(
    () => availableOccasions.value.find((item) => item.isSelected) ?? null
  );

  const currentSelectedLineItem = ref<LineItem | null>(null);

  const lineItemsSearchValue = ref("");

  const linkedItems = ref<LinkedItem[]>([]);

  const isBadLinkedItems = ref<boolean>(false);

  const isApproximationPopupOpened = ref(false);

  const occasionExtendedInfo = ref<Record<string, unknown>>({});

  const campaignMainOptions = ref<string[]>(["Sprite_RTE_Launch_2023"]);

  const activationPlatformOptions = ref<ActivationPlatformOption[]>([]);

  const campaignOptions = ref<Campaign[]>([]);

  const insertionOrderOptions = ref<InsertionOrder[]>([]);

  const linkedOccasionPlacements = ref<ResponseLinkedOccasion[]>([]);

  const isLinkDisabled = ref(false);

  const availableCampaigns = ref<ApiPullCampaign[]>([]);

  const isCreateCampaignFinished = ref(false);

  const itemsWithErrors = ref<Record<string, number>>({});

  const fetchLinkedItems = async () => {
    isBadLinkedItems.value = false;
    let params: LinkedOccasionParams = {
      client: campaignParams.client?.id ?? "",
      brand: campaignParams.selectedStrategy?.brands[0] ?? "",
      category: campaignParams.category?.id ?? "",
      dsp_type: campaignParams.activationPlatform?.value?.dsp_type ?? "",
      advertiser_id: campaignParams.activationPlatform?.value?.advertiserId?.toString() ?? "",
      campaign_id: campaignParams.campaign?.id?.toString() ?? "",
    };

    if (campaignParams.insertionOrder?.id) {
      params = { ...params, insertion_order_ids: [campaignParams.insertionOrder?.id] as string[] };
    }

    const data = await useOccasionsLinkedOccasionsRead(params);

    if (data) {
      linkedOccasionPlacements.value = data;

      const prepared = data.map((item) => {
        try {
          const lineItem = availableLineItems.value.find((lineItem) => lineItem.id === item.line_item_id);
          return responseLinkedToLinkedItem(item, lineItem);
        } catch (e) {
          console.error(`Received bad linked occasions data: ${e}`);
          return null;
        }
      });

      if (prepared.includes(null)) {
        isBadLinkedItems.value = true;
        showToast({
          summary: lang.error.somethingWentWrong,
          severity: "error",
        });

        if (isYoutubeCase.value) {
          linkedItems.value = [];
          return;
        }
      }

      linkedItems.value = prepared.filter((linkedItem) => {
        return !!linkedItem;
      }) as LinkedItem[];
    }
  };

  const isDrawerVisible = ref(false);
  const drawerViewMode = ref(false);
  const drawerOccasion = reactive({} as OccasionDrawerItem);
  const availableDuplicates = ref([] as ApiDuplicate[]);

  const updateCampaignClient = (value?: Product) => {
    value
      ? (campaignParams.client = {
          name: value.name,
          id: value.id,
          dsp: value.dsp,
        })
      : null;
  };

  const updateCampaignCategory = (value?: ProductCategory) => {
    campaignParams.category = value ? { name: value.name, id: value.id } : null;
  };

  const updateCampaignBrand = (value?: ProductBrand) => {
    campaignParams.brand = value ? { name: value.name, id: value.id } : null;
  };

  const updateCampaignStartDate = (value: PullCampaign["start_date"]) => {
    if (campaignParams.campaignMain) {
      campaignParams.campaignMain.start_date = value;
    }
  };

  const updateCampaignEndDate = (value: PullCampaign["end_date"]) => {
    if (campaignParams.campaignMain) {
      campaignParams.campaignMain.end_date = value;
    }
  };

  const updateCampaignPlatforms = (value: PullCampaign["platforms"]) => {
    if (campaignParams.campaignMain) {
      campaignParams.campaignMain.platforms = value;
    }
  };

  const updateCampaignName = (value: PullCampaign["name"]) => {
    if (campaignParams.campaignMain) {
      campaignParams.campaignMain.name = value ? value.trim() : null;
    }
  };

  const updateCampaignParam = (key: CampaignParamsKey, value: CampaignParams[keyof CampaignParams] | null): void => {
    switch (key) {
      case CampaignParamsKey.SelectedStrategy: {
        campaignParams.selectedStrategy = value as CampaignParams["selectedStrategy"];
        break;
      }

      case CampaignParamsKey.Product:
        campaignParams.product = value as CampaignParams["product"];
        break;

      case CampaignParamsKey.CampaignMain:
        campaignParams.campaignMain = value as CampaignParams["campaignMain"];
        break;

      default: {
        campaignParams[key] = value as CampaignObjectParam & CampaignActionName;
        break;
      }
    }
  };

  const clearCampaignParams = () => {
    for (const key in campaignParams) {
      campaignParams[key as keyof CampaignParams] = null;
    }
  };

  const clearSelectedOccasion = () => {
    const previousSelectedItem = availableOccasions.value.find((occasion) => occasion.isSelected);
    if (previousSelectedItem) {
      previousSelectedItem.isSelected = false;
    }
  };

  const clearSelectedLineItem = () => {
    currentSelectedLineItem.value = null;
  };

  const updateSelectedOccasion = (item: Occasion) => {
    clearSelectedOccasion();

    const currentSelectedItem = availableOccasions.value.find((occasion) => occasion.title === item.title);
    if (currentSelectedItem) {
      currentSelectedItem.isSelected = true;
    }
  };

  const updateDrawerStatus = (newValue: boolean): void => {
    isDrawerVisible.value = newValue;
  };

  const openOccasionDrawer = (value: { value: DataItem; viewMode: boolean }) => {
    Object.assign(drawerOccasion, value.value);
    updateDrawerStatus(true);
    drawerViewMode.value = value.viewMode;
  };

  const updateAvailableOccasions = (data: Occasion[]) => {
    availableOccasions.value = data;
  };

  const updateAvailableOccasionSets = (data: OccasionsSetPostResponse[]) => {
    availableOccasionSets.value = data;
  };

  const updateAvailableLineItems = (data: LineItem[]) => {
    availableLineItems.value = data;
  };

  const updateIsApproximationPopupOpened = (value: boolean) => {
    isApproximationPopupOpened.value = value;
  };

  const updateCurrentSelectedLineItem = (value: LineItem | null) => {
    currentSelectedLineItem.value = value;
  };

  const updateLinkedItems = (value: LinkedItem[]) => {
    linkedItems.value = value;
  };

  const updateLineItemsSearchValue = (value: string) => {
    lineItemsSearchValue.value = value.trim();
  };

  const updateCampaignOptions = (value: Campaign[]) => {
    campaignOptions.value = value;
  };

  const setInsertionOrderOptions = (value: InsertionOrder[]) => {
    insertionOrderOptions.value = value;
  };

  const updateActivationPlatformOptions = (value: ActivationPlatformOption[]) => {
    activationPlatformOptions.value = value;
  };

  const updateCreateCampaignFinished = (value: boolean) => {
    isCreateCampaignFinished.value = value;
  };

  const updateCampaignMainOptions = (value: string[]) => {
    campaignMainOptions.value = value;
  };

  const handleStatus = async (params: { item: LinkedItem; status: string; job: JobResponseBody }) => {
    const { status, job, item } = params;
    const { responseStatus } = job;

    const isResponseStatusError = responseStatus?.toLowerCase() === JobOperationStatus.Error;
    const isYoutube = item.lineItem?.is_youtube;

    if (status === JobStatus.Finished && !isResponseStatusError) {
      switch (item.status) {
        case Status.PushStarted:
          await changeItemsStatus(
            item,
            isYoutube ? youtubeFlowTrigger.onPushSuccess : standardFlowTrigger.onPushSuccess,
            false
          );

          await fetchOccasions();
          break;

        case Status.RecallStarted:
          await changeItemsStatus(
            item,
            isYoutube ? youtubeFlowTrigger.onRecallSuccess : standardFlowTrigger.onRecallSuccess,
            false
          );
          await fetchOccasions();
          break;

        case YoutubeStatus.PushValidationStarted:
          await changeItemsStatus(item, youtubeFlowTrigger.onPushValidateSuccess, false);
          await fetchOccasions();
          break;

        case YoutubeStatus.RecallValidationStarted:
          await changeItemsStatus(item, youtubeFlowTrigger.onRecallValidateSuccess, false);
          await fetchOccasions();
          break;
      }
    }

    if (status === JobStatus.Error || isResponseStatusError) {
      switch (item.status) {
        case Status.PushStarted:
          await changeItemsStatus(
            item,
            isYoutube ? youtubeFlowTrigger.onPushError : standardFlowTrigger.onPushError,
            false
          );
          break;
        case Status.RecallStarted:
          await changeItemsStatus(item, standardFlowTrigger.onRecallError, false);
          break;
        case YoutubeStatus.PushValidationStarted:
          await changeItemsStatus(item, youtubeFlowTrigger.onPushValidateError, false);
          break;
        case YoutubeStatus.RecallValidationStarted:
          await changeItemsStatus(item, youtubeFlowTrigger.onRecallValidateError, false);
          break;
      }
    }
  };

  const linkedOccasionsTimerId = ref<number | null>(null);

  const DATA_REFRESH_PERIOD = 10_000;

  let jobs = reactive<Record<string, boolean>>({});

  const checkLinkedOccasionsTable = async () => {
    const statusesForChecking = uniq([
      Status.PushStarted,
      Status.RecallStarted,
      YoutubeStatus.PushStarted,
      YoutubeStatus.PushValidationStarted,
      YoutubeStatus.RecallStarted,
      YoutubeStatus.RecallValidationStarted,
    ]);

    const loadingItems = linkedItems.value.filter((item) => statusesForChecking.includes(item.status as Status));
    for (const item of loadingItems) {
      if (!item.jobId) {
        console.error("Job id is empty", item);
        continue;
      }

      if (jobs[item.jobId]) {
        continue;
      }

      jobs[item.jobId] = true;
      try {
        const jobResult = await useJobStatusRead(item.jobId, true);

        if (!jobResult?.status) {
          delete jobs[item.jobId];
          continue;
        }

        delete jobs[item.jobId];
        const preparedItem = cloneDeep(item);

        if (jobResult?.occasionInstanceId) {
          preparedItem.occasionInstanceId = jobResult?.occasionInstanceId;
        }

        if (jobResult?.jobId) {
          preparedItem.jobId = jobResult?.jobId;
        }

        await handleStatus({
          status: jobResult.status,
          item: preparedItem,
          job: jobResult,
        });

        await fetchLinkedItems();
      } catch (e) {
        itemsWithErrors.value[item.occasionInstanceId] = !itemsWithErrors.value[item.occasionInstanceId]
          ? 1
          : ++itemsWithErrors.value[item.occasionInstanceId];

        if (itemsWithErrors.value[item.occasionInstanceId] < COUNT_JOB_REQUEST_ATTEMPTS) {
          delete jobs[item.jobId];
        }

        if (itemsWithErrors.value[item.occasionInstanceId] >= COUNT_JOB_REQUEST_ATTEMPTS) {
          showToast({
            summary: lang.message.occasion,
            severity: "error",
            detail: lang.error.somethingWentWrong,
          });
        }
      }
    }
  };

  const startLinkedOccasionsDataUpdate = () => {
    linkedOccasionsTimerId.value = window.setInterval(async () => {
      try {
        await checkLinkedOccasionsTable();
      } catch (e) {
        await showToast({
          summary: lang.error.checkLinkedOccasions,
          severity: "error",
          detail: lang.error.somethingWentWrong,
        });
      }
    }, DATA_REFRESH_PERIOD);
  };

  const stopLinkedOccasionsDataUpdate = () => {
    if (linkedOccasionsTimerId.value) {
      window.clearInterval(linkedOccasionsTimerId.value);
      linkedOccasionsTimerId.value = null;
    }
  };

  const updateOccasionExtendedInfo = (value: OccasionExtendedInfo): void => {
    occasionExtendedInfo.value = value;
  };

  const updateAvailableDuplicates = (value: ApiDuplicate[]): void => {
    availableDuplicates.value = value;
  };

  const isYoutubeCase = computed((): boolean => {
    return !!campaignParams.insertionOrder?.lineitems?.[0]?.is_youtube;
  });

  const selectedStrategyId = computed(() => {
    return campaignParams.selectedStrategy?.id;
  });

  const fetchOccasions = async () => {
    if (!selectedStrategyId.value) {
      return;
    }

    availableOccasionSets.value = await useOccasionsSetsRead({ strategy_id: selectedStrategyId.value as string });
  };

  const fetchCampaigns = async () => {
    if (campaignParams?.selectedStrategy?.id) {
      const apiCampaigns: ApiPullCampaign[] = await useCampaignsRead({
        strategy_id: campaignParams.selectedStrategy.id,
      });
      availableCampaigns.value = cloneDeep(apiCampaigns);
    }
  };

  const updateLinkDisabled = (val: boolean) => {
    if (val) {
      clearSelectedOccasion();
      clearSelectedLineItem();
    }

    isLinkDisabled.value = val;
  };

  const checkError = (occasionInstanceId: string) => {
    return itemsWithErrors.value[occasionInstanceId] >= COUNT_JOB_REQUEST_ATTEMPTS;
  };

  const resetJobs = () => {
    itemsWithErrors.value = {};
    jobs = reactive({});
  };

  return {
    campaignParams,
    availableOccasions,
    availableOccasionSets,
    currentSelectedOccasion,
    currentSelectedLineItem,
    linkedItems,
    lineItemsSearchValue,
    isDrawerVisible,
    drawerOccasion,
    availableLineItems,
    isApproximationPopupOpened,
    drawerViewMode,
    occasionExtendedInfo,
    availableDuplicates,
    campaignOptions,
    insertionOrderOptions,
    campaignMainOptions,
    activationPlatformOptions,
    isYoutubeCase,
    linkedOccasionPlacements,
    isLinkDisabled,
    itemsWithErrors,
    availableCampaigns,
    isCreateCampaignFinished,

    updateCampaignParam,
    clearSelectedOccasion,
    clearSelectedLineItem,
    updateSelectedOccasion,
    updateDrawerStatus,
    openOccasionDrawer,
    updateAvailableOccasions,
    clearCampaignParams,
    fetchLinkedItems,
    updateAvailableLineItems,
    updateIsApproximationPopupOpened,
    checkLinkedOccasionsTable,
    startLinkedOccasionsDataUpdate,
    stopLinkedOccasionsDataUpdate,
    updateOccasionExtendedInfo,
    updateCampaignClient,
    updateCampaignCategory,
    updateCampaignBrand,
    updateAvailableDuplicates,
    fetchOccasions,
    updateLinkDisabled,
    fetchCampaigns,
    checkError,
    resetJobs: resetJobs,
    updateCampaignStartDate,
    updateCampaignEndDate,
    updateCampaignPlatforms,
    updateCampaignName,
    updateAvailableOccasionSets,
    updateCurrentSelectedLineItem,
    updateLinkedItems,
    updateLineItemsSearchValue,
    updateCampaignOptions,
    updateActivationPlatformOptions,
    updateCampaignMainOptions,
    updateCreateCampaignFinished,
    setInsertionOrderOptions,
  };
});
