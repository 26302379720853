import { Lang } from "@/types/lang";

export default <Lang>{
  action: "Action",
  endpointTableTitle: "Endpoint",
  domainTableTitle: "Domain",
  descriptionTableTitle: "Description",
  duplicationLevel: "Duplication Level",
  iabCategoriesTableTitle: "IAB Categories",
  keywordsTableTitle: "Keywords/Labels",
  mediaPropertyTableTitle: "Media Property",
  mediaTypeTableTitle: "Media Type",
  name: "Name",
  occasion: "Occasion",
  placement: "Placement",
  reach: "Reach",
  status: "Status",
};
