import { Config, useApi } from "@/api";
import { API_ROUTES } from "@/api/apiRoutes";
import { useAppStore } from "@/store/app/appStore";
import { HookStandardConfig } from "@/types/api";
import { OccasionsSetPostResponse } from "@/types/occasionSetTypes";

type OccasionsSetsParams = {
  strategy_id: string;
};

export const useOccasionsSetsRead = async (
  params: OccasionsSetsParams,
  config?: HookStandardConfig
): Promise<OccasionsSetPostResponse[]> => {
  const appStore = useAppStore();
  const { updateIsLoading, showToast } = appStore;

  const apiConfig: Config = {
    path: API_ROUTES.OCCASIONS_SETS as string,
    queryParams: params,
  };

  const { request, data } = useApi<OccasionsSetPostResponse[]>(apiConfig);

  updateIsLoading(true);

  try {
    await request();

    if (config?.successMessage) {
      showToast({
        severity: "success",
        detail: config.successMessage,
      });
    }

    if (data.value) {
      return data.value;
    }
  } catch (e) {
    if (config?.errorMessage) {
      showToast({
        severity: "error",
        detail: config.errorMessage,
      });
    }

    return [];
  } finally {
    updateIsLoading(false);
  }

  return [];
};
